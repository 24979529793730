<template>
  <el-row>
    <el-col :span="24" v-loading="loading" element-loading-text="拼命加载中">
      <!--工具条-->
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.businessId" placeholder="业务ID" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.businessName" placeholder="业务名称" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleReset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="showAddDialog">新增</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="syncToRedis">全量同步</el-button>
        </el-form-item>
      </el-form>
      <!--列表-->
      <el-table :data="listData">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="businessId" label="业务id" width="100"></el-table-column>
        <el-table-column prop="businessName" label="业务名称" width="250"></el-table-column>
        <el-table-column prop="smsSwitch" label="短信开关" :formatter="smsSwitchFormatter"></el-table-column>
        <el-table-column prop="messageSwitch" label="消息开关" :formatter="messageSwitchFormatter"></el-table-column>
        <el-table-column prop="pushSwitch" label="推送开关" :formatter="pushSwitchFormatter"></el-table-column>
        <el-table-column prop="status" label="状态" :formatter="statusFormatter"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column>
        <el-table-column label="操作" fixed="right" width="240">
          <template slot-scope="scope">
            <el-button size="small" @click="showEditDialog(scope.$index, scope.row)">编辑</el-button>
            <el-button size="small" @click="setStatusData(scope.row.businessId, 2)" v-show="scope.row.status == '1'">禁用</el-button>
            <el-button size="small" @click="setStatusData(scope.row.businessId, 1)" v-show="scope.row.status == '2'">启用</el-button>
            <el-button size="small" type="danger" @click="deleteData(scope.row.businessId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="10"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      >
      </el-pagination>
      <!--新增编辑界面-->
      <el-dialog :title="addEditTitle" :visible.sync="addEditFormVisible" :close-on-click-modal="false">
        <el-form :model="addEditForm" label-width="100px" :rules="addEditFormRules" ref="addEditForm">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="业务名称" prop="businessName" label-width="80px">
                <el-input v-model="addEditForm.businessName" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12">
              <el-form-item label="短信开关" prop="smsSwitch" label-width="80px">
                <el-switch v-model="addEditForm.smsSwitch" active-value="1" inactive-value="2"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="短信类型" prop="smsType" label-width="130px">
                <el-select v-model="addEditForm.smsType" placeholder="请选择">
                  <el-option v-for="item in smsTypeOptions" :key="item.id" :label="item.value" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="短信模板" prop="smsTemplate" label-width="80px">
                <el-input type="textarea" v-model="addEditForm.smsTemplate" :rows="3" maxlength="200" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="8">
              <el-form-item label="消息开关" prop="messageSwitch" label-width="80px">
                <el-switch v-model="addEditForm.messageSwitch" active-value="1" inactive-value="2"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="消息类型" prop="type" label-width="80px">
                <el-select v-model="addEditForm.type" placeholder="请选择">
                  <el-option v-for="item in msgTypeOptions" :key="item.id" :label="item.value" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="消息种类" prop="messageType" label-width="80px">
                <el-select v-model="addEditForm.messageType" placeholder="请选择">
                  <el-option v-for="item in messageTypeOptions" :key="item.id" :label="item.value" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12">
              <el-form-item label="推送开关" prop="pushSwitch" label-width="80px">
                <el-switch v-model="addEditForm.pushSwitch" active-value="1" inactive-value="2"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="消息跳转按钮名称" prop="toButtonName" label-width="130px">
                <el-input v-model="addEditForm.toButtonName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="12">
              <el-form-item label="推送标题" prop="pushTemplateTitle" label-width="80px">
                <el-input v-model="addEditForm.pushTemplateTitle"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="跳转页面" prop="toPageType" label-width="130px">
                <el-select v-model="addEditForm.toPageType" placeholder="请选择">
                  <el-option-group v-for="group in jumpOptions" :key="group.label" :label="group.label">
                    <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="推送模板" prop="pushTemplate" label-width="80px">
                <el-input type="textarea" v-model="addEditForm.pushTemplate" size="medium"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addEditFormVisible = false">取消</el-button>
          <el-button v-if="addEditType === 'add'" type="primary" @click.native="addSubmit">保存</el-button>
          <el-button v-else type="primary" @click.native="editSubmit">保存</el-button>
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>
import API from '@/api/system/api_message'
import PagesMixins from '@/mixins/pagesMixins'
import _ from 'lodash'
import { smsTypeOptions, msgTypeOptions, messageTypeOptions, jumpOptions } from '@/config/config'
const searchForm = {
  businessId: '',
  businessName: ''
}
const addEditForm = {
  businessName: '',
  smsSwitch: '1',
  smsType: '',
  smsTemplate: '',
  messageSwitch: '1',
  messageType: '',
  type: '',
  pushSwitch: '1',
  pushTemplateTitle: '',
  pushTemplate: '',
  toPageType: '',
  toButtonName: ''
}
export default {
  mixins: [PagesMixins],
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: [],
      loading: false,
      smsTypeOptions,
      msgTypeOptions,
      messageTypeOptions,
      jumpOptions,
      addEditForm: _.cloneDeep(addEditForm),
      addEditTitle: '',
      addEditType: '',
      addEditFormVisible: false,
      addEditFormRules: {
        businessName: [{ required: true, message: '请输入业务模板名称', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    statusFormatter(row) {
      return row.status === 1 ? '启用' : '禁用'
    },
    smsSwitchFormatter(row) {
      return row.smsSwitch === 1 ? '开' : '关'
    },
    messageSwitchFormatter(row) {
      return row.messageSwitch === 1 ? '开' : '关'
    },
    pushSwitchFormatter(row) {
      return row.pushSwitch === 1 ? '开' : '关'
    },
    getEnforceFlag(row, column, cellValue) {
      return cellValue ? '是' : '否'
    },
    handleSearch() {
      this.total = 0
      this.page = 1
      this.getListData()
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      this.loading = true
      API.queryBusinessTemplateList(params).then(result => {
        this.loading = false
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.listData = result.data.list
        } else {
          this.$message.error({ showClose: true, message: result.msg, duration: 2000 })
        }
      })
    },
    //新增
    showAddDialog() {
      if (this.$refs['addEditForm'] !== undefined) {
        this.$refs['addEditForm'].resetFields()
      }
      this.addEditTitle = '新增'
      this.addEditType = 'add'
      this.addEditForm = _.cloneDeep(addEditForm)
      this.addEditFormVisible = true
    },
    addSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          API.addBusinessTemplate(params).then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: '新增成功', duration: 2000 })
              this.addEditFormVisible = false
              this.getListData()
            } else {
              this.$message.error({ showClose: true, message: '新增失败', duration: 2000 })
            }
          })
        }
      })
    },
    //显示编辑界面
    showEditDialog(index, row) {
      if (this.$refs['addEditForm'] !== undefined) {
        this.$refs['addEditForm'].resetFields()
      }
      this.addEditTitle = '编辑'
      this.addEditType = 'edit'
      this.addEditForm = Object.assign({}, row)
      this.addEditForm.smsSwitch = row.smsSwitch.toString()
      this.addEditForm.messageSwitch = row.messageSwitch.toString()
      this.addEditForm.pushSwitch = row.pushSwitch.toString()
      this.addEditFormVisible = true
    },
    //编辑
    editSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          API.editBusinessTemplate(params).then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: result.msg || '修改成功', duration: 2000 })
              this.addEditFormVisible = false
              this.getListData()
            } else {
              this.$message.error({ showClose: true, message: result.msg || '修改失败', duration: 2000 })
            }
          })
        }
      })
    },
    //停用启用
    setStatusData(businessId, status) {
      let params = {
        businessId: businessId,
        status: status
      }
      API.setStatusData(params).then(result => {
        this.loading = false
        if (result && result.code === '000000') {
          this.$message.success({ showClose: true, message: result.msg || '设置成功', duration: 2000 })
          this.getListData()
        } else {
          this.$message.error({ showClose: true, message: result.msg || '设置失败', duration: 2000 })
        }
      })
    },
    //删除
    deleteData(businessId) {
      let params = {
        businessId
      }
      API.delBusinessTemplate(params).then(result => {
        if (result && result.code === '000000') {
          this.$message.success({ showClose: true, message: result.msg || '删除成功', duration: 2000 })
          this.getListData()
        } else {
          this.$message.error({ showClose: true, message: result.msg || '删除失败', duration: 2000 })
        }
      })
    },
    //同步数据to Redis
    syncToRedis() {
      API.syncAll().then(result => {
        if (result && result.code === '000000') {
          this.$message.success({ showClose: true, message: result.msg || '同步成功', duration: 2000 })
        } else {
          this.$message.error({ showClose: true, message: result.msg || '同步失败', duration: 2000 })
        }
      })
    }
  }
}
</script>
